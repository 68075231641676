@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('./fonts/open-sans/opensanslight.woff2') format('woff2'),
    url('./fonts/open-sans/opensanslight.woff') format('woff'),
    url('./fonts/open-sans/opensanslight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'),
    local('OpenSans'),
    url('./fonts/open-sans/opensans.woff2') format('woff2'),
    url('./fonts/open-sans/opensans.woff') format('woff'),
    url('./fonts/open-sans/opensans.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
    url('./fonts/open-sans/opensanssemibold.woff2') format('woff2'),
    url('./fonts/open-sans/opensanssemibold.woff') format('woff'),
    url('./fonts/open-sans/opensanssemibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('./fonts/open-sans/opensansbold.woff2') format('woff2'),
    url('./fonts/open-sans/opensansbold.woff') format('woff'),
    url('./fonts/open-sans/opensansbold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'),
    url('./fonts/open-sans/opensansextrabold.woff2') format('woff2'),
    url('./fonts/open-sans/opensansextrabold.woff') format('woff'),
    url('./fonts/open-sans/opensansextrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('./fonts/open-sans/opensansitalic.woff2') format('woff2'),
    url('./fonts/open-sans/opensansitalic.woff') format('woff'),
    url('./fonts/open-sans/opensansitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

html {
  width: 100vw;
  height: 100vh;
  height: stretch;
}

#root {
  width: 100vw;
  height: 100vh;
  height: stretch;
}

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  height: stretch;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

input::placeholder {
  color: #adb4bd;
  font-size: 17px;
}

.fill-available {
  width: 100vw;
  height: 100vh;
  height: stretch;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

.no-webp .bg-mage-1 {
  background-image: url("assets/images/page1/bg/bg3x.png");
}

.webp .bg-mage-1 {
  background-image: url("assets/images/page1/bg/bg@3x.webp");
}

.bg-mage-2 {
  background: #F2F2F2;
}

.webp .bg-mage-3 {
  background-image: url("assets/images/page3/bg/bg@3x.webp");
}
.no-webp .bg-mage-3 {
  background-image: url("assets/images/page3/bg/bg@3x.png");
}



button {
  font-family: inherit;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@keyframes fade {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slide {
  from {
    transform: translate3d(40px, 0, 0);
  }
  to {
    transform: translate3d(-341px, 0, 0);
  }
}
